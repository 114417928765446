@import '~stream-chat-react/dist/css/v2/index.css';

body,
html {
  background-color: hsl(215, 50%, 6%) !important;
  overscroll-behavior: none;
  /* min-width: 1280px; */
  /* prevent mui modal moving content on windows */
  padding: 0 !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 16px;
  background-image: transparent;
}

html > ::-webkit-scrollbar {
  background-image: linear-gradient(180deg, #000e21 0%, #001534 100%);
}

::-webkit-scrollbar-track {
  background: #19293e;
  border-radius: 10px;
  background-clip: padding-box;
  border: 6px solid transparent;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%);
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 4px;
}

html > ::-webkit-scrollbar-track-piece:end {
  background-image: linear-gradient(180deg, #000e21 0%, #001534 100%);
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 4px;
}

html > ::-webkit-scrollbar-track-piece:start {
  background-image: linear-gradient(180deg, #000e21 0%, #001534 100%);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

html > ::-webkit-scrollbar-corner {
  background-image: linear-gradient(180deg, #000e21 0%, #001534 100%);
}

@media screen and (max-width:654px){
  .hide-mobile {
    display: none;
  }
}

@media screen and (min-width:655px){
  .hide-desktop {
    display: none;
  }
}

@media screen and (min-width:940px){
  .header-short-only {
    display: none;
  }
}

/* prevent mui modal moving content on windows */
header {
  padding-right: 0 !important;
}

[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/assets/icons/Close.svg');
  background-size: cover;
  width: 10px;
  height: 10px;
}

:root {
  --w3m-z-index: 10000 !important;
}

.visible {
  visibility: visible;
}

.invisible {
  display: none;
  visibility: hidden;
}

.markdown-links a {
  color: #fff;
}

.glider-prev,
.glider-next {
  z-index: 100 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.glider-prev {
  left: 0 !important;
}

.glider-next {
  right: 0 !important;
}

.glider-children-container > div {
  display: flex;
  flex-direction: row;
}

.glider-children-container {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.glider-children-container::-webkit-scrollbar {
  display: none;
}

.glider-container > .glider-track > .glider-slide {
  width: fit-content !important;
}

.glider-container > .glider-track {
  gap: 12px !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

#inline-span > span {
  display: block !important;
}

.str-chat {
  --str-chat__font-family: Barlow !important;
  --str-chat__headline-text: 400 18px Barlow;
  --str-chat__channel-preview-unread-badge-background-color: linear-gradient(90deg, #4DC8FF -33%, #0064E9 200%);
  --str-chat__text-low-emphasis-color: rgba(204, 226, 255, 0.7);
  --str-chat__own-message-reaction-background-color: rgba(128, 183, 255, 0.16);
  --str-chat__message-reaction-background-color: rgba(128, 183, 255, 0.16);
  --str-chat__message-reaction-border-radius: 16px;
  --str-chat__channel-list-empty-indicator-color: rgba(204, 226, 255, 0.7);
  --str-chat__audio-attachment-widget-background-color: rgba(0, 27, 59, 0.8);
  --str-chat__audio-attachment-controls-button-background-color: rgba(77, 151, 255, 0.10);
  --str-chat__file-attachment-background-color: rgba(0, 27, 59, 0.8);
  --str-chat__text-color: #FFFFFF;
  --str-chat__message-textarea-color: #FFFFFF;
  --str-chat__message-send-color: #399CFA;
  --str-chat__audio-attachment-controls-button-color: #399CFA;
  --str-chat__attachment-margin: 4px;
  /* Input field background color */
  --str-chat__message-textarea-background-color: hsla(215, 50%, 12%, 0.8);
  --str-chat__message-textarea-border-block-start: none;
  --str-chat__message-textarea-border-inline-start: none;
  --str-chat__message-textarea-border-inline-end: none;
  --str-chat__message-textarea-border-block-end: none;
  --str-chat__message-send-disabled-background-color: hsla(215, 50%, 12%, 0.8);
  --str-chat__message-send-background-color: hsla(215, 50%, 12%, 0.8);
  --str-chat__message-send-border-radius: 0 10px 10px 0;
  --str-chat__message-input-tools-color: #399CFA;
  --str-chat__message-textarea-border-radius: 10px 0 0 10px;
  --str-chat__suggestion-list-container-background-color: #020206 ;
  --str-chat__suggestion-list-container-color: #020206 ;
  --str-chat__suggestion-list-item--selected-background-color: #020206 ;
  --str-chat__mention-list-user-item-at-sign-color: #1376FB;
}

.str-chat__message-reaction > button:hover, .str-chat__message-reaction-own > button:hover {
  cursor: pointer;
}

.str-chat__message-reaction:hover, .str-chat__message-reaction-own:hover {
  border: 1px solid rgba(204, 226, 255, 0.7) !important;
}

.str-chat__message-reaction, .str-chat__message-reaction-own {
  border: 1px solid transparent !important;
}

.str-chat__message-input {
  background: transparent; 
  width: 100% !important;
}

.str-chat__channel, 
.str-chat__list, 
.str-chat__channel-header,
.str-chat__empty-channel,
.str-chat__channel-list {
  background:hsl(215, 50%, 6%) !important;
}

.str-chat__thread, .str-chat__channel-list {
  border: none;
}

.str-chat__thread-start, .str-chat__channel-list-empty-v1, .str-chat__list::-webkit-scrollbar, 
.str-chat__channel-list-messenger-react__main::-webkit-scrollbar, .str-chat__loading-channel, 
.str-chat__loading-channels, .str-chat__tooltip, .str-chat__modal__close-button {
  display: none !important;
}

.str-chat__message-list-scroll {
  padding: 0px 8px 60px 8px !important;
}

.str-chat__channel-header-title {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.str-chat__avatar-image {
  background: none !important;
}

.str-chat__empty-channel svg {
  width: 75px;
  height: 75px;
  color: rgba(204, 226, 255, 0.7);
}

.str-chat__empty-channel svg path {
  fill: rgba(204, 226, 255, 0.7);
}

.str-chat__channel-list-empty,
.str-chat__empty-channel-text {
  color: rgba(204, 226, 255, 0.7);
}

.str-chat__empty-channel-text {
  font-family: Barlow;
  font-size: 18px;
}

.str-chat__channel {
  border-radius: 0 0 10px 10px;
}

.str-chat__main-panel-inner, .str-chat__thread-list {
  height: 100% !important;
}

.str-chat__parent-message-li {
  margin: 0 !important;
  border-bottom: none !important;
}

.str-chat__thread-container {
  background: linear-gradient(180deg, #001533 0%, #000E21 82.29%) !important;
  position: absolute;
  top: 0;
  right: 0;
}

.str-chat__list-notifications, .str-chat__message-simple-text-inner p  {
  margin: 0;
}

.str-chat__notification {
  background: rgb(101, 32, 17);
  border: 1px solid #FE512B;
  font-family: 'Barlow';
  font-weight: 600;
  letter-spacing: 0.15px;
  font-size: 16px;
  color: #ff613d;
}

.str-chat__message--system__text, .str-chat__message--system__date {
  color: rgba(204, 226, 255, 0.7) !important;
}

.str-chat__list {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.str-chat__message-attachment-file--item-download svg path { 
  fill: #399CFA !important;
}

.str-chat__message-attachment-audio-widget--title, .str-chat__message-attachment-file--item-name {
  font-size: 14px !important;
}

.str-chat__message-sender-avatar, .str-chat__avatar-image {
  height: 32px !important;
  width: 32px !important;
}

.str-chat__user-item:hover {
  background: rgba(128, 183, 255, 0.08);
}

.str-chat__message-textarea-with-emoji-picker {
  padding-right: 0 !important;
}

.str-chat__unread-messages-separator {
  background: #4DC8FF;
  color: #FFFFFF;
}

em-emoji-picker {
  --background-rgb: 0, 27, 59;
  --category-icon-size: 20px;
  --color-border: hsla(215, 50%, 12%, 0.8);
  --font-family: Barlow;
  --font-size: 16px;
  --rgb-accent: 0, 100, 233;
  --rgb-background: 0, 27, 59;
  --rgb-color: 255, 255, 255;
  --rgb-input: 0, 27, 59;
}

.str-chat__send-button {
  padding-bottom: 10px !important;
  align-items: flex-end !important;
  justify-content: center;
  width: 48px;
}

.str-chat__send-button > svg {
  height: 24px !important;
  width: 24px !important;
}

.str-chat__send-button:disabled {
  background-color: hsla(215, 50%, 12%, 0.8) !important;
}

.str-chat__attachment-preview-image {
  height: 72px;
  width: 72px;
  min-width: 72px;
}

.str-chat__attachment-preview-thumbnail {
  height: 72px;
  width: 72px;
}

.str-chat__message-textarea-container {
  min-height: 45px;
}

.str-chat__file-input-label > svg {
  height: 24px;
  width: 24px;
}

.str-chat__message-textarea {
  font-size: 16px;
}

.str-chat__message-textarea::-webkit-scrollbar, .str-chat__attachment-list-scroll-container::-webkit-scrollbar {
  display: none;
}

mux-player::part(bottom pip button) {
  order: 2;
}

mux-player::part(bottom fullscreen button) {
  order: 3;
}